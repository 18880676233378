<template>
  <div class="max-w-screen-tv-main-axis max-h-screen-tv-cross-axis mx-auto py-2 text-center">
    <Header :back="false" :language="lang" @langchanged="langchanged" :languageVisibility="false"/>
    <div class="text-center mt-64">
      <img v-bind:src="getLogo()" alt="Logo" class="w-44 mx-auto">
      <h2 class="font-heading text-h2 text-redDamask dark:text-redDamask mt-10 mb-2">{{ $t('language.selectTitle') }}</h2>
      <p class="font-body text-bodyM text-sepiaBlack dark:text-halfColonialWhite">{{ $t('language.text') }}</p>
      <Language flag tv :language="lang" @langchanged="langchanged" class="mt-6"/>
      <Button href="/tvs" :text="$t('language.saveBtn')" buttonType="primary" fullWidth class="mt-24"/>
    </div>
  </div>
</template>

<script>

import Header from '@/components/Header';
import Language from '@/components/Language';
import Button from '@/components/Button.vue';

export default {
  name: 'LanguageTV',
  components: {
    Language,
    Button,
    Header
  },
  data() {
    return {
      lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
    }
  },
  methods: {
    getLogo: function() {
      const element = document.getElementById("app");
      if (element.classList.contains('darkMode')) {
        return require("../assets/logo_dark.png");
      } else {
        return require("../assets/logo_light.png");
      }
    },
    langchanged(value) {
        this.lang = value;
        console.log('llsel ' + value);
      },
  }
}
</script>
